import { useSelector } from "react-redux";

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {

  const uat = useSelector((state: any) => state.uat.uat);
  const access_token = useSelector((state: any) => state.aT.aT);

  // if (localStorage.getItem("user_access_token") != null) {
  if (access_token != null) {
    // if (localStorage.getItem('uat') === undefined || localStorage.getItem('uat') === null) {
    if (uat === undefined || uat === null) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      var as = uat;
      if (as != null && window.atob(as) === "BASIC_USER") {
        if (window.location.pathname.includes('/dashboard') || window.location.pathname.includes('/oauth/redirect')) {
          return outlet;
        } else {
          window.location.href = "/dashboard";
        }
      }
      if (as != null && window.atob(as) === "COMPLIANCE_OFFICER" && localStorage.getItem("add_edit_user") === "true") {
        if (window.location.pathname.includes('/organization')) {
          return outlet;
        } else {
          window.location.href = "/organization";
        }
      }
    }
    return outlet;
  } else {
    localStorage.clear();
    window.location.href = "/login";
    return null;
  }
}
