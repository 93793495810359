import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WebService from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { aiM, aT, iA, oA, userEmail, userId, userName, userRole } from "../../../Redux/actions";

interface PropData {
  customClass?: any;
  message?: any;
  messageText?: any;
  hideLoder?: any;
}

const TableLoader = (props: PropData) => {
  const access_token = useSelector((state: any) => state.aT.aT);
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const dispatch = useDispatch();
  var params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.token) {
      localStorage.setItem("user_access_token", params.token);
      dispatch(aT(params.token));

      meCall();
    }
  }, []);
  const meCall = () => {
    return WebService.getAPI({
      action: `me`,
      body: null,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        dispatch(userName(res.first_name + " " + res.last_name));
        dispatch(userEmail(res.email));
        dispatch(userId(res.id));
        dispatch(userRole(res.role.role));
        dispatch(iA(res.client?.inbound_archiving));
        dispatch(oA(res.client?.outbound_archiving));
        // dispatch(aiM(res.client?.aiModel?.aiModelName));
        navigate("/dashboard");
      })
      .catch((error) => {
        WebService.logout()
        return error;
      });
  };

  return (
    <>
      {
        !props.hideLoder ?
          <div className="mb-0 table-wrap position-relative w-100">
            <div className={props.customClass
              ? props.customClass + " component-loader" : "component-loader"}>
              <div className="loader"></div><br />
              {
                props.message ?
                  <>
                    <span className="font-16" style={{ position: "absolute", paddingTop: "80px" }}>Please wait...</span>
                    <span className="font-14" style={{ position: "absolute", paddingTop: "125px" }}>{props.messageText}</span>
                  </>
                  : ""
              }
            </div>
          </div>
          :
          null
      }
    </>
  );
};

export default TableLoader;
