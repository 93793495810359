import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Row } from "react-bootstrap";
import "./card.scss";
import WebService from "../../../Services/WebService";
import CreditCard from "./CreditCard";
import TableLoader from "../TableLoader/TableLoader";
import Stripe from "../Stripe/Stripe";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";

interface PropData {
    show: boolean;
    action: string;
    type: any;
    trxId: any;
    data?: any;
    parentCallback?: any;
    parentCallbackRefreshList?: any
    paymentStatus?: any;
    parentCallbackIsClose?: any;
    screen?: any
}

const PaymentCards = (props: PropData) => {
    const t_id = useSelector((state: any) => state.tI.tenantId);
    const access_token = useSelector((state: any) => state.aT.aT);
    const [showPaymentCard, setShowPaymentCard] = useState(false);
    const [cardList, setCardList] = useState<any[]>([]);
    const [loader, setLoader] = useState<any>(false);
    const [fullPageloader, setFullPageLoader] = useState<any>(false);
    const [intentId, setIntentId] = useState<any>();
    const [tId, setTid] = useState<any>();
    const [isPaymentDone, setIsPaymentDone] = useState<any>(false);

    useEffect(() => {
        getCardList();
    }, [props.data?.totalAmount]);

    useEffect(() => {
        if (props.data?.totalAmount > 0) {
            setShowPaymentCard(props.show);
        }
    }, [props.data?.totalAmount]);


    const getCardList = () => {
        setLoader(true);
        if (props.screen !== 'PAY') {
            return WebService.getAPI({
                action: `subscription/cards`,
                body: null,
                isShowError: true,
                access_token: access_token,
                t_id: t_id
            })
                .then((res: any) => {
                    setLoader(false);
                    if (res?.length > 0) {
                        setCardList(res);
                    }
                    else {
                        handleAddClick()
                    }
                })
                .catch((error: any) => {
                    return error;
                });
        } else {
            handleAddClick()
        }

    };

    const handleAddClick = (id?: any) => {
        if (id) {
            setFullPageLoader(true)
        }
        setLoader(true)
        WebService.addLoader("save11")
        var obj = {
            "type": undefined,
            "user": 0,
            "connector": 0,
            "sms": 0,
            "website": 0,
            "imessage": 0,
            "chat": 0,
            "amount": undefined,
            "transactionType": undefined,
            "transactionId": undefined,
            "cardToken": undefined
        }

        if (props.type === "PLAN") {
            obj.transactionType = props.type;
            obj.transactionId = props.trxId;
            // obj.amount = props.data.totalAmount;
            obj.cardToken = id ? id : undefined;


        } else {
            obj.type = props.data.type;
            obj.transactionType = props.type;
            obj.cardToken = id ? id : undefined;
            obj.sms = props.data.smsNo;
            obj.website = props.data.websiteNo;
            obj.connector = props.data.connector;
            obj.chat = props.data.chat;
            obj.amount = props.data.totalAmount
            obj.user = props.data.user
            obj.imessage = props.data.imessage
        }
        if (props.data?.totalAmount > 0 || props.type === "PLAN") {
            var url = `subscription/payment-intent`;
            if (props.screen === 'PAY') {
                url = `subscription/pay/payment-intent`;
            }
            return WebService.postAPI({
                action: url,
                body: obj,
                isShowError: true,
                access_token: access_token,
                t_id: t_id
            })
                .then((res: any) => {
                    setTid(res.id)
                    if (id) {
                        setIsPaymentDone(true);
                        setLoader(false);
                        setFullPageLoader(false);
                        successAlert();
                    } else {
                        setIsPaymentDone(false);
                        setIntentId(res.uuid);
                        setLoader(false);
                        setFullPageLoader(false)
                    }
                    WebService.removeLoader("save11");
                    // props.parentCallback(true)
                })
                .catch((error: any) => {
                    setLoader(false);
                    WebService.removeLoader("save11");
                    return error;
                });
        }
    };

    const handleClosePaymentCard = () => {
        props.parentCallbackIsClose(false);
        setShowPaymentCard(false);
    };

    const successAlert = () => {
        Swal.fire({
            title: 'Transaction successful!',
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(function () {
            if (props.type === "PLAN") {
                window.location.href = "/dashboard"
            } else {
                props.parentCallbackRefreshList(true);
            }
        });
    }

    const onSuccessPayment = (data: any) => {
        setFullPageLoader(true)
        const obj = {
            "intentId": data.paymentIntent.id,
            "id": props.trxId ? props.trxId : tId,
            "paymentStatus": props.paymentStatus ?? undefined
        }

        var url = `subscription/payment-success`;
        if (props.screen === 'PAY') {
            url = `subscription/pay/payment-success`;
        }
        return WebService.postAPI({
            action: url,
            body: obj,
            isShowError: true,
            access_token: access_token,
            t_id: t_id
        })
            .then((res: any) => {
                setIsPaymentDone(true);
                setLoader(false);
                setFullPageLoader(false)
                successAlert();
            })
            .catch((error: any) => {
                setIsPaymentDone(false);
                setLoader(false);
                setFullPageLoader(false)
                return error;
            });
    }



    return (

        !fullPageloader
            ?
            isPaymentDone ?
                null
                :
                <Modal show={props.show} onHide={handleClosePaymentCard} centered>
                    <Modal.Header closeButton
                        onClick={() => setShowPaymentCard(false)}
                        className=" border-0 pb-0"
                    >
                        <Modal.Title>Select Card</Modal.Title>
                    </Modal.Header>
                    {
                        !loader ?
                            intentId ?
                                <Modal.Body>
                                    <Stripe paymentIntent={intentId}
                                        onSuccess={(data: any) => {
                                            onSuccessPayment(data);
                                        }}
                                        onFailed={(data: any) => {
                                        }}>
                                    </Stripe>
                                </Modal.Body>
                                :
                                <> <Modal.Body>
                                    <Row>
                                        {
                                            cardList?.map((item: any, index: any) => {
                                                return (
                                                    <CreditCard id={item.id} card={item.card.brand} lastFourDigit={item.card.last4} expMonth={item.card.expMonth} expYear={item.card.expYear} sendId={(id: any) => { handleAddClick(id) }} />
                                                )
                                            })
                                        }
                                        <div className={cardList?.length === 0 ? "col-md-6 mx-auto" : "col-md-6"} onClick={() => { handleAddClick() }}>
                                            <div className="credit-card selectable add-new-card" >
                                                <div>+ Add New</div>
                                            </div>
                                        </div>


                                    </Row>
                                </Modal.Body >
                                    <Modal.Footer className=" justify-content-center border-0 pt-0">
                                        {/* <Button className="btn-brand-light" onClick={handleClosePaymentCard} >
                                            Cancel
                                        </Button> */}
                                        {/* <Button className="btn-brand-1" id="save11" onClick={() => { handleAddClick() }}>
                                            + Add New
                                        </Button> */}
                                    </Modal.Footer>
                                </>

                            : <Modal.Body><TableLoader customClass="table-loader" /></Modal.Body>

                    }

                </Modal >
            : <FullPageLoader show={true}></FullPageLoader>
    );
};

export default PaymentCards;