import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import AIDatePicker from "../Common/AIDatePicker/AIDatePicker";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";

interface propsData {
  reviewId: any;
  type: any;
  websiteId?: any
}
const ReviewAllFilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [connectors, setConnectors] = useState<any>([]);
  const [allConnectors, setAllConnectors] = useState<any>([]);
  const [connectorError, setConnectorsError] = useState<any>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    unregister,
  } = useForm();

  var watchVariable = watch();

  useEffect(() => {
    watchVariable.websiteId = props.websiteId;
    setValue("websiteId", props.websiteId);
    watchVariable.startDate = startDate;
    setValue("startDate", startDate);
    watchVariable.endDate = endDate;
    setValue("endDate", endDate);
    watchVariable.percentage = 10;
    setValue("percentage", 10);
  }, []);

  const handleReviewAll = () => {
    if (watchVariable.percentage !== "") {
      clearErrors("percentage");
    } else {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }
    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    const newArray = connectors?.map((item: any) => {
      let social_type = item;
      let account_type = "";

      if (item.value.includes('PERSONAL')) {
        social_type = item.value.replace('_PERSONAL', '');
        account_type = 'PERSONAL';
      } else if (item.value.includes('BUSINESS')) {
        social_type = item.value.replace('_BUSINESS', '');
        account_type = 'BUSINESS';
      } else {
        social_type = item.value;
        account_type = '';
      }
      return {
        socialType: social_type,
        account_type: account_type
      };
    });
    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
      return false;
    }

    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
      return;
    } else {
      clearErrors("endDate");
    }
    WebService.addLoader("btn-save");
    return WebService.postAPI({
      action: `review`,
      body: { ...watchVariable, "accounts": newArray, "request_type": "", "accountType": "" },
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        WebService.removeLoader("btn-save");
        props.reviewId(res.uuid);
      })
      .catch((error: any) => {
        WebService.removeLoader("btn-save");
        return error;
      });
  };
  const handleError = (errors: any) => {
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.name === "" || watchVariable.name === undefined) {
      setError("name", {
        type: "custom",
        message: "Please enter name",
      });
    }
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }

    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
    }

    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
    } else {
      clearErrors("endDate");
    }
  };
  const handleChange = (type: any, value: any) => {
    if (type === "name") {
      watchVariable.name = value;
      setValue("name", value);
      if (watchVariable.name !== "") {
        clearErrors("name");
      } else {
        setError("name", {
          type: "custom",
          message: "Please enter name",
        });
      }
    } else if (type === "percentage") {
      watchVariable.percentage = value;
      setValue("percentage", value);
      if (watchVariable.percentage !== "") {
        clearErrors("percentage");
      } else {
        setError("percentage", {
          type: "custom",
          message: "Please enter percentage",
        });
      }
    } else if (type === "startDate") {
      watchVariable.startDate = value;
      setValue("startDate", value);
      if (watchVariable.startDate !== "") {
        clearErrors("startDate");
      } else {
        setError("startDate", {
          type: "custom",
          message: "rt",
        });
      }
    } else if (type === "endDate") {

      watchVariable.endDate = value;
      setValue("endDate", value);
      if (watchVariable.endDate !== "") {
        clearErrors("endDate");
      } else {
        setError("endDate", {
          type: "custom",
          message: "er",
        });
      }
    }
  };
  const onDateChange = (date: any, type: any) => {
    if (type === "startDate") {
      setStartDate(date);
      watchVariable.startDate = date;
      setValue("startDate", date);
    } else if (type === "endDate") {
      setEndDate(date);
      watchVariable.endDate = date;
      setValue("endDate", date);
    }
  };


  const connectorPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      WebService.getAPI({
        action: `user-integration/list`,
        body: null,
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            const modifiedIntegrationDetails: any[] = [];

            res.map((channel: any) => {
              if (
                channel.type === "INSTAGRAM" ||
                channel.type === "LINKEDIN" ||
                channel.type === "X"
              ) {
                let hasBusinessAccount = false;
                let hasPersonalAccount = false;

                if (Array.isArray(channel?.list)) {
                  channel?.list?.forEach((item: any) => {
                    if (item.accountType === "BUSINESS") {
                      hasBusinessAccount = true;
                    }
                    if (item.accountType === "PERSONAL") {
                      hasPersonalAccount = true;
                    }
                  });
                }

                if (hasBusinessAccount) {
                  const businessIntegration = { value: channel.type + "_" + "BUSINESS", label: channel.type + " " + "BUSINESS" };
                  modifiedIntegrationDetails.push(businessIntegration);
                }

                if (hasPersonalAccount) {
                  const personalIntegration = { value: channel.type + "_" + "PERSONAL", label: channel.type + " " + "PERSONAL" };
                  modifiedIntegrationDetails.push(personalIntegration);
                }
              } else {
                modifiedIntegrationDetails.push({ value: channel.type, label: channel.type.replaceAll("_", " ") });
              }
            });

            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...modifiedIntegrationDetails];
            setAllConnectors(options);
            resolve(options);
          } else {
            setAllConnectors([]);
            resolve([]);
          }
        })
        .catch((error: any) => {
          setAllConnectors([]);
          resolve([]);
          return error;
        });
    });

  const onConnectorSelection = (selectedOptions: any) => {
    setConnectorsError("");
    const isSelectAllSelected = selectedOptions.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];


    if (isSelectAllSelected) {
      arr = allConnectors
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOptions;
    }

    setConnectors(arr);
  };



  return (
    <>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleReviewAll, handleError)}
      >
        <div className="px-3 pt-3">
          <Row>
            <Col>
              <FloatingLabel
                controlId="ReviewPercentage"
                label="Review list name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  maxLength={255}
                  {...register("name", {
                    required: true,
                  })}
                  onChange={(e: any) => {
                    handleChange("name", e.target.value);
                  }}
                />
                {errors.name && (
                  <Label
                    title={
                      errors.name.message ? errors.name.message.toString() : ""
                    }
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>
        </div>
        <div className="px-3 mb-3">
          <Row>
            <Col>
              <InputGroup className=" perce-adon ">
                <Form.Control
                  value={"Reviews percentage"}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="font-12 font-medium"
                />
                <InputGroup.Text id="basic-addon2">
                  <span className="">{watchVariable.percentage}%</span>{" "}
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <FloatingLabel
                controlId="ReviewPercentage"
                label="Review Percentage"
                className=""
              >
                <Form.Control
                  type="text"
                  {...register("percentage", {
                    required: true,
                    pattern: {
                      value: /^[1-9]\d?$|^100$/,
                      message: "Please enter a valid number between 1 and 100",
                    },
                  })}
                  value={watch("percentage") || ""}
                  onChange={(e: any) => {
                    const enteredValue = e.target.value;
                    if (parseInt(enteredValue) > 100) {
                      setError("percentage", {
                        type: "custom",
                        message:
                          "Please enter a valid number between 1 and 100",
                      });
                    } else {
                      handleChange("percentage", enteredValue);
                    }
                  }}
                />
                {errors.percentage && (
                  <Label
                    title={
                      errors.percentage.message
                        ? errors.percentage.message.toString()
                        : ""
                    }
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>
        </div>
        <div className="px-3">
          <div className="">
            <div>
              <Row>
                <Col>
                  <AIDatePicker
                    date={startDate}
                    selectDate={(date: any) => onDateChange(date, "startDate")}
                    maxDate={new Date()}
                  />
                </Col>
                <Col>
                  <AIDatePicker
                    {...register("endDate")}
                    date={endDate}
                    selectDate={(date: any) => onDateChange(date, "endDate")}
                    maxDate={new Date()}
                  />
                </Col>
              </Row>

            </div>
            <div style={{ marginLeft: "7px" }}>

            </div>
          </div>
          <div className="mb-1">
            {errors.endDate && (
              <Label
                title={
                  errors.endDate.message?.toString() ?? "Please select end date"
                }
                modeError={true}
                showStar={true}
                type=""
              />
            )}
          </div>

          <div className="mb-3">
            <AsyncSelect
              cacheOptions
              defaultOptions
              onChange={onConnectorSelection}
              isMulti
              loadOptions={connectorPromiseOptions}
              placeholder="Select channels"
              value={connectors}
            />
            <span className="text-danger font-12">{connectorError}</span>
          </div>
          <Button type="submit" className="btn btn-brand-1 w-100" id="btn-save">
            Start Review
          </Button>
          <span className="mt-2 text-danger font-12">Note: The "Review All" feature will not include messages that are already flagged or escalated.</span>
        </div>
      </Form>
    </>
  );
};

export default ReviewAllFilterBlade;















